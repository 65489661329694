import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './index.scss';
import Classnames from "classnames";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputQuery: props.text || '',
    };

    this.changeSort = this.changeSort.bind(this);
    this.changeQuery = this.changeQuery.bind(this);
  }
  changeSort(newSort) {
    if (this.props.handleSortChange) {
      this.setState({
        inputQuery: ''
      }, () => {
        this.props.handleSortChange(newSort);
      });
    }

  }
  changeQuery() {
    if (this.props.handleTextChange) {
      this.searchInput.blur();
      this.props.handleTextChange(this.state.inputQuery);
    }
  }
  render() {
    const { t, sort } = this.props;

    return (
      <div className="row">
        <div className="searchBar">
          <div className="inputContainer">
            <input type="search"
              ref={input => this.searchInput = input}
              value={this.state.inputQuery}
              onChange={(e) => this.setState({ inputQuery: e.target.value })}
              onKeyUp={(e) => e.keyCode === 13 && this.changeQuery()}
              placeholder={t('explore.searchPlaceholder')} />
          </div>
          <div className="buttonsContainer">
            <div className={"button " + (sort === 'priority' ? 'active' : 'inactive')} onClick={() => this.changeSort('priority')}>{t('explore.picked')}</div>
            <div className={"button " + (sort === 'popular' ? 'active' : 'inactive')} onClick={() => this.changeSort('popular')}>{t('explore.popular')}</div>
            <div className={"button " + (sort === 'new' ? 'active' : 'inactive')} onClick={() => this.changeSort('new')}>{t('explore.newest')}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('translations')(Search);
