import React, { Component } from 'react';
import styles from './index.scss';
import Search from './../components/search/index.jsx';
import PresentationsGrid from 'components/presentation-grid/index.jsx';
import Loader from 'components/loader.jsx';
import ReactPaginate from 'react-paginate';
import HomeCarousel from './../components/header-carousel';
import LogotypesStrip from 'components/logotypesStrip';
import Classnames from 'classnames';
import { translate } from "react-i18next";
import AssetHelper from 'AssetHelper';

const DefaultSort = 'priority';

class Explore extends Component {
  constructor(props) {
    super(props);

    let searchParams = new URLSearchParams(window.location.search);

    this.state = {
      pageNum: searchParams.get('pageNum') || 1,
      pageSize: 12,
      pagesCount: 0,
      items: [],
      text: decodeURI(searchParams.get('text') || ''),
      sort: decodeURI(searchParams.get('sort') || DefaultSort),
      carouselPosition: 0,
    };

    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleCarouselChange = this.handleCarouselChange.bind(this);
  }
  handlePageChange(e) {
    this.setState({
      pageNum: (e.selected + 1)
    }, () => {
      this.makeApiCall({});
    });
  }
  handleSortChange(newSort) {
    if (this.state.sort !== newSort) {
      this.setState({
        text: '',
        sort: newSort,
        pageNum: 1
      }, this.makeApiCall);
    }
  }
  handleTextChange(newText) {
    if (newText !== this.state.text || this.state.sort !== DefaultSort) {
      if (newText === '') {
        this.setState({
          text: '',
          sort: DefaultSort,
          pageNum: 1
        }, this.makeApiCall);
      } else {
        this.setState({
          text: newText,
          sort: '',
          pageNum: 1
        }, this.makeApiCall);
      }
    }
  }
  handleCarouselChange(e) {
    let index = (e.item && e.item.index) || -1;

    if (index > -1 && index !== this.state.carouselPosition) {
      this.setState({
        carouselPosition: index,
      });
    }
  }
  componentDidMount() {
    const { match } = this.props,
      sort = match.params.sort;

    if (sort && sort !== this.state.sort) {
      this.setState({
        sort,
      }, this.makeApiCall);
    } else {
      this.makeApiCall();
    }
  }
  componentWillReceiveProps(nextProps) {
    let nextSort = nextProps.match.params.sort;

    if (nextSort && this.state.sort !== nextSort) {
      this.setState({
        sort: nextSort,
      }, this.makeApiCall);
    }
  }
  makeApiCall() {
    let { text, sort, pageNum, pageSize } = this.state,
      action,
      payload,
      locationParams = {},
      newLocation = '/explore';

    if (pageNum > 1) {
      locationParams.pageNum = pageNum;
    }

    pageNum--;

    payload = {
      pageNum,
      pageSize,
    };

    if (text) {
      action = this.props.fetchPresentationsSearch;
      payload.text = text;
      locationParams.text = text;
    } else {
      action = this.props.fetchPresentationsGet;
      payload.sort = sort;
      if (sort !== DefaultSort) {
        locationParams.sort = sort;
      }
    }

    if (locationParams.sort === '') {
      locationParams.sort = 'priority';
    }
    // Map payload to array and join with '&'
    newLocation += '?' + Object.keys(locationParams).map(k => `${k}=${locationParams[k]}`).join('&');
    // escape special characters like ' ' to '%20'
    newLocation = encodeURI(newLocation);
    this.props.history.replace(newLocation);

    action(payload);
  }
  render() {
    const { presentations, isLoading, metadata, user, t } = this.props,
      { sort, carouselPosition, pageNum } = this.state,
      carouselItems = [{
        title: 'Fabryka sensu',
        image: AssetHelper.staticPath('/static/home/explore/img-explore-carousel1.jpg'),
        link: 'https://evryplace.com/p/epzgoa',
        id: 1,
      }, {
        title: 'JLL Warsaw Spire',
        image: AssetHelper.staticPath('/static/home/explore/img-explore-carousel2.jpg'),
        link: 'https://evryplace.com/p/bcabaf',
        id: 2,
      }, {
        title: 'Bristol',
        image: AssetHelper.staticPath('/static/home/explore/img-explore-carousel3.jpg'),
        link: 'https://evryplace.com/p/muofhw',
        id: 3,
      }, {
        title: 'Bangeston',
        image: AssetHelper.staticPath('/static/home/explore/img-explore-carousel4.jpg'),
        link: 'https://evryplace.com/p/fyriku',
        id: 4,
      }],
      isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    let searchResult = (<div className="row">
      <PresentationsGrid items={presentations} loading={isLoading} />
      <ReactPaginate
        forcePage={(pageNum-1)}
        containerClassName="pagination"
        pageCount={metadata.pagesCount}
        onPageChange={this.handlePageChange}
      />
    </div>);

    return (
      <section className="explore">
        {!isIE11 &&
          <HomeCarousel
            items={carouselItems}
            startPosition={carouselPosition}
            onChange={this.handleCarouselChange}
          />
        }
        <LogotypesStrip />
        <Search
          handleSortChange={this.handleSortChange}
          handleTextChange={this.handleTextChange}
          sort={this.state.sort}
          text={this.state.text}
        ></Search>
        {searchResult}
        <Loader className={this.props.isLoading ? '' : 'isHidden'}></Loader>
      </section>
    );
  }
}

export default translate("translations")(Explore);

