import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'shared/redux/presentation';
import Embed from './containers/index.jsx';
import { actions as uiActions } from 'shared/redux/ui';

const {
  getPresentation,
  getPresentationMeta,
  isLoading,
  isFound,
  passwordRequested,
  getError,
} = selectors;

const {
  fetchPresentation,
  clearPresentation,
  increasePresentationViewCount,
} = actions;

const {
  updateUi,
} = uiActions;

const mapStateToProps = state => ({
  presentation: getPresentation(state),
  presentationMeta: getPresentationMeta(state),
  isLoading: isLoading(state),
  isFound: isFound(state),
  passwordRequested: passwordRequested(state),
  error: getError(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchPresentation,
    clearPresentation,
    updateUi,
    increasePresentationViewCount,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Embed);
