import React, { Component } from "react";
import { translate } from "react-i18next";
import OwlCarousel from "react-owl-carousel2";
import './index.scss';
import IconArrowSmallLeft from 'home/icons/arrow-small-left.svg';
import IconArrowSmallRight from 'home/icons/arrow-small-right.svg';

class HomeCarousel extends Component {
  constructor(props) {
    super(props);

    this._onChange = this._onChange.bind(this);
  }
  _onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = true,
      oldItems = this.props.items || [],
      newItems = nextProps.items || [];
    
    if (oldItems.length !== newItems.length) {
      shouldUpdate = false;
    } else {
      for (let i = 0; i < newItems.length; i++) {
        if (oldItems[i] !== newItems[i]) {
          shouldUpdate = false;
          break;
        }
      }
    }
    return shouldUpdate;
  }
  render() {
    const { t, items, startPosition = 0 } = this.props;
    
    const options = {
        items: 1,
        nav: false,
        dots: false,
        rewind: true,
        autoplay: false,
        loop: true,
        startPosition,
      },
      events = {
        onChange: this._onChange,
      };

    return (

      <section className="carousel carousel--explore">

        <div className="carousel__arrows carousel__arrows--explore">
          <div className="carousel__prev" onClick={() => this.refs.homecarousel.prev()}>
            <IconArrowSmallLeft />
          </div>
          <div className="carousel__next" onClick={() => this.refs.homecarousel.next()}>
            <IconArrowSmallRight />
          </div>
        </div>

        <OwlCarousel
          className="carousel__slides owl-carousel"
          ref="homecarousel"
          options={options}
          events={events}
        >
          {items.map(item => {
            return (
              <div
                className="carousel__slide carousel__slide--explore"
                data-slide={item.id}
                key={item.id}
                style={{ backgroundImage: 'url(' + item.image + ')' }}
              >
                <div className="carousel__container carousel__container--explore wrapper">
                  <h1 className="carousel__title carousel__title--explore" dangerouslySetInnerHTML={{ __html: item.title }} />
                  <a href={item.link} className="carousel__button carousel__button--explore button button--transparent">
                    {t('explore.button')}
                  </a>
                </div>
              </div>
            );
          })}
        </OwlCarousel>

      </section>
    );
  }
}

export default translate("translations")(HomeCarousel);
