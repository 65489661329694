const tabsComponent = props => (
  <div className="tabs-container__content-container">
    <div className={`tabs-container__img tabs-container__img-product-${props.val + 1}`} />
    <div className="tabs-container__content">
      <h3>{props.label}</h3>
      <p className="tabs-container__description">{props.description}</p>
    </div>
  </div>
);

export default tabsComponent;
//
