import React, { Component } from "react";
import { translate } from "react-i18next";
import CtaBlock from "../../components/cta-block";
import styles from "./index.scss";
import Tab from "./components/tab/index";
import { NavBarStyles } from "components/navBar";
import MetaTags from 'react-meta-tags';

class Faq extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.navBar.setStyle('dark');
  }
  componentWillUnmount() {
    this.props.navBar.setStyle();
  }
  render() {
    const { t } = this.props,
      faqs = Array.from({length: 11}, (v, k) => {
        let question = t('faq.q' + (k + 1)),
          answer = t('faq.a' + (k + 1));
        return <Tab key={k} question={question} answer={answer} />;
      });

    return (
      <div className="faq-page">
        <MetaTags>
          <title>{t("faq.metaTitle")}</title>
          <meta name="description" content={t("faq.metaDescription")} />
          <meta name="keywords" content={t("faq.metaKeywords")} />
        </MetaTags>
        <section className="faq-page__section">
          <h1>{t('faq.title')}</h1>
          {faqs}
        </section>
        <CtaBlock />
      </div>
    );
  }
}

export default translate("translations")(Faq)
