import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'shared/redux/presentations';
import Explore from './containers/index.jsx';
import { selectors as authSelectors } from 'shared/redux/auth';

const {
	getMetadata,
	getPresentations,
	isLoading,
} = selectors;

const {
  fetchPresentationsSearch,
  fetchPresentationsGet,
  fetchPresentationsProfile,
} = actions;

const {
  getCurrentUser
} = authSelectors;

const mapStateToProps = state => ({
	presentations: getPresentations(state),
	metadata: getMetadata(state),
	isLoading: isLoading(state),
  user: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
	...bindActionCreators({
    fetchPresentationsSearch,
    fetchPresentationsGet,
    fetchPresentationsProfile,
	}, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Explore);
