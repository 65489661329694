import React, { Component } from 'react';
import Classnames from 'classnames';
import IconArrow from 'static/home/icons/arrow.svg';

class Tab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({ open: !this.state.open });
  }
  render() {
    const { question, answer } = this.props,
      { open } = this.state;
    
    const tabClass = Classnames({
      'faq-point': true,
      'faq-point--open': open,
    });
    
    return (
      <div className={tabClass}>
        <div className="faq-point__top" onClick={this.handleClick}>
          <div>{question}</div>
          <div className="faq-point__arrow">
            <IconArrow />
          </div>
        </div>
        <div className="faq-point__answer">
          {answer}
        </div>
      </div>
    );
  }
}

export default Tab;
